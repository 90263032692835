import { motion, useInView } from 'framer-motion';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface ROIInputs {
  serviceType: 'voice' | 'chatbot' | null;
  employeeCostPerMonth: number;
  employeeCount: number;
  volumePerMonth: number; // calls for voice, interactions for chatbot
  averageMinutesPerCall?: number; // only for voice
  peakConcurrentCalls: number; // for voice
  peakConcurrentChats: number; // for chatbot
}

interface ROIResults {
  currentMonthlyCost: number;
  newMonthlyCost: number;
  setupFee: number;
  monthlySavings: number;
  monthlyROI: number;
  yearlyGrossSavings: number;
  yearlyROI: number;
  paybackPeriodMonths: number;
  volumeMetrics: string;
  concurrencyFee: number;
}

const ROICalculator = () => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  const [inputs, setInputs] = useState<ROIInputs>({
    serviceType: null,
    employeeCostPerMonth: 5000,
    employeeCount: 1,
    volumePerMonth: 500,
    averageMinutesPerCall: 5,
    peakConcurrentCalls: 10, // minimum 10 concurrent calls
    peakConcurrentChats: 40, // minimum 40 concurrent chats
  });

  const [results, setResults] = useState<ROIResults | null>(null);

  // Auto-select Voice Agent when component comes into view
  useEffect(() => {
    if (isInView && !inputs.serviceType) {
      setInputs(prev => ({ ...prev, serviceType: 'voice' }));
    }
  }, [isInView]);

  const [showResults, setShowResults] = useState(false);

  const calculateConcurrencyFee = (peakCalls: number): number => {
    // $11 per concurrent line, no free tier
    const pricePerLine = 11;
    return peakCalls * pricePerLine;
  };

  const calculateVoiceAgentCost = (minutes: number): { monthlyCost: number; setupFee: number } => {
    let setupFee = 1500; // Default setup fee for basic tiers
    let monthlyCost = 0;
    const concurrencyFee = calculateConcurrencyFee(inputs.peakConcurrentCalls);

    if (minutes <= 1000) {
      monthlyCost = 500; // $0.50/min
    } else if (minutes <= 5000) {
      monthlyCost = 1500; // $0.30/min
    } else if (minutes <= 10000) {
      monthlyCost = 2500; // $0.25/min
    } else {
      // Over 10,000 minutes
      monthlyCost = 2500 + ((minutes - 10000) * 0.24); // Base + $0.24 per additional minute
      setupFee = 2500; // Updated from 3000 to 2500 for complex setup
    }

    // Add concurrency fee to monthly cost
    monthlyCost += concurrencyFee;

    return { monthlyCost, setupFee };
  };

  const calculateChatbotCost = (interactions: number): { monthlyCost: number; setupFee: number; concurrentChatCost: number; additionalChatLines: number } => {
    let setupFee = 1000; // Default setup fee for basic tiers
    let monthlyCost = 0;
    const concurrencyFee = inputs.peakConcurrentChats * 5; // $5 per concurrent chat

    if (interactions <= 5000) {
      monthlyCost = 500; // $0.10 per interaction
      setupFee = 1000;
    } else if (interactions <= 10000) {
      monthlyCost = 800; // $0.08 per interaction
      setupFee = 1500;
    } else if (interactions <= 20000) {
      monthlyCost = 1500; // Unlimited up to 20,000
      setupFee = 1500;
    } else {
      // Over 20,000 interactions
      monthlyCost = 1500 + ((interactions - 20000) * 0.04); // Base + $0.04 per additional interaction
      setupFee = 1500;
    }

    // Calculate concurrent chat cost (first 40 are free, then $10 per additional line)
    const additionalChatLines = Math.max(0, inputs.peakConcurrentChats - 40);
    const concurrentChatCost = additionalChatLines * 10;
    monthlyCost += concurrentChatCost;

    return { monthlyCost, setupFee, concurrentChatCost, additionalChatLines };
  };

  const calculateROI = () => {
    if (!inputs.serviceType) return;

    let costs;
    if (inputs.serviceType === 'voice' && inputs.averageMinutesPerCall) {
      const monthlyMinutes = inputs.volumePerMonth * inputs.averageMinutesPerCall;
      costs = calculateVoiceAgentCost(monthlyMinutes);
    } else {
      costs = calculateChatbotCost(inputs.volumePerMonth);
    }

    const { monthlyCost, setupFee } = costs;

    // Calculate total monthly labor cost before automation
    const currentMonthlyCost = inputs.employeeCostPerMonth * inputs.employeeCount;

    // Monthly savings is the difference between current cost and new cost
    const monthlySavings = currentMonthlyCost - monthlyCost;

    // Monthly ROI is the monthly savings
    const monthlyROI = monthlySavings;

    // Yearly ROI calculation
    const yearlyGrossSavings = monthlyROI * 12;
    const yearlyROI = yearlyGrossSavings - setupFee; // Subtract setup fee from yearly savings

    // Payback period in months = setup fee / monthly savings
    const paybackPeriodMonths = setupFee / monthlySavings;

    // Add total volume to results for verification
    const volumeMetrics = inputs.serviceType === 'voice'
      ? `${(inputs.volumePerMonth * (inputs.averageMinutesPerCall || 0)).toFixed(0)} minutes (${inputs.volumePerMonth} calls × ${inputs.averageMinutesPerCall} min)`
      : `${inputs.volumePerMonth} interactions`;

    setResults({
      currentMonthlyCost,
      newMonthlyCost: monthlyCost,
      setupFee,
      monthlySavings,
      monthlyROI,
      yearlyGrossSavings,
      yearlyROI,
      paybackPeriodMonths,
      volumeMetrics,
      concurrencyFee: inputs.serviceType === 'voice' ? calculateConcurrencyFee(inputs.peakConcurrentCalls) : 0
    });

    setShowResults(true);
  };

  const handleInputChange = (field: keyof ROIInputs, value: number | 'voice' | 'chatbot' | null) => {
    setInputs(prev => ({
      ...prev,
      [field]: value
    }));
    setShowResults(false);
  };

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const formatNumber = (number: number): string => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }).format(number);
  };

  return (
    <section id="roi-calculator" className="py-24 bg-black relative overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-accent-blue/5 to-black" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-accent-purple/10 via-transparent to-transparent" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative" ref={ref}>
        {/* Section Header */}
        <motion.div
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl md:text-5xl font-display font-bold mb-4">
            <span className="bg-gradient-to-r from-accent-blue via-accent-purple to-accent-pink text-transparent bg-clip-text">
              ROI Calculator
            </span>
          </h2>
          <p className="text-white/70 max-w-2xl mx-auto">
            Calculate your potential savings with our AI solutions
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-12 items-start">
          {/* Calculator Form */}
          <motion.div
            className="space-y-6"
            initial={{ opacity: 0, x: -20 }}
            animate={isInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div className="bg-white/5 backdrop-blur-lg rounded-xl border border-white/10 p-6 space-y-6">
              {/* Service Selection */}
              <div className="space-y-4">
                <label className="block text-white mb-2">Select Service</label>
                <div className="grid grid-cols-2 gap-4">
                  <button
                    onClick={() => handleInputChange('serviceType', 'voice')}
                    className={`p-4 rounded-lg text-center transition ${
                      inputs.serviceType === 'voice'
                        ? 'bg-accent-blue text-white'
                        : 'bg-black/50 border border-white/10 text-white/70 hover:border-accent-blue'
                    }`}
                    aria-label="Select Voice Agent"
                  >
                    AI Voice Agent
                  </button>
                  <button
                    onClick={() => handleInputChange('serviceType', 'chatbot')}
                    className={`p-4 rounded-lg text-center transition ${
                      inputs.serviceType === 'chatbot'
                        ? 'bg-accent-blue text-white'
                        : 'bg-black/50 border border-white/10 text-white/70 hover:border-accent-blue'
                    }`}
                    aria-label="Select Chatbot"
                  >
                    AI Chatbot
                  </button>
                </div>
              </div>

              {/* Common Inputs */}
              <div>
                <label htmlFor="employeeCostPerMonth" className="block text-white mb-2">Employee Cost per Month ($)</label>
                <div>
                  <input
                    id="employeeCostPerMonth"
                    type="number"
                    className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-accent-blue"
                    value={inputs.employeeCostPerMonth}
                    onChange={(e) => handleInputChange('employeeCostPerMonth', Number(e.target.value))}
                    aria-label="Monthly cost per employee in dollars"
                    min="0"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="employeeCount" className="block text-white mb-2">Number of Employees</label>
                <div>
                  <input
                    id="employeeCount"
                    type="number"
                    className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-accent-blue"
                    value={inputs.employeeCount}
                    onChange={(e) => handleInputChange('employeeCount', Number(e.target.value))}
                    aria-label="Number of employees"
                    min="1"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="volumePerMonth" className="block text-white mb-2">
                  {inputs.serviceType === 'voice' ? 'Calls per Month (Total Calls)' : 'Interactions per Month'}
                </label>
                <div>
                  <input
                    id="volumePerMonth"
                    type="number"
                    className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-accent-blue"
                    value={inputs.volumePerMonth}
                    onChange={(e) => handleInputChange('volumePerMonth', Number(e.target.value))}
                    aria-label={`Monthly ${inputs.serviceType === 'voice' ? 'calls' : 'chat interactions'}`}
                    min="0"
                  />
                </div>
              </div>

              {/* Voice-specific Inputs */}
              {inputs.serviceType === 'voice' && (
                <div>
                  <div className="flex items-center gap-2 mb-2">
                    <label htmlFor="peakConcurrentCalls" className="text-white">Peak Concurrent Calls</label>
                    <div className="relative group">
                      <span className="cursor-help text-white/60">!</span>
                      <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 p-2 bg-black/90 rounded-lg text-sm text-white/80 w-64 hidden group-hover:block">
                        Each concurrent call line represents dedicated GPU computing power. $11/month per line. Minimum 10 lines required.
                      </div>
                    </div>
                  </div>
                  <input
                    id="peakConcurrentCalls"
                    type="number"
                    className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 text-white focus:border-accent-blue focus:outline-none"
                    value={inputs.peakConcurrentCalls}
                    onChange={(e) => handleInputChange('peakConcurrentCalls', Math.max(10, parseInt(e.target.value) || 10))}
                    aria-label="Peak concurrent calls"
                    min="10"
                  />
                  <p className="mt-1 text-sm text-white/60">
                    ${calculateConcurrencyFee(inputs.peakConcurrentCalls)}/month ({inputs.peakConcurrentCalls} lines at $11/month)
                  </p>
                </div>
              )}
              {inputs.serviceType === 'voice' && (
                <div>
                  <label htmlFor="averageMinutesPerCall" className="block text-white mb-2">Average Minutes per Call</label>
                  <div>
                    <input
                      id="averageMinutesPerCall"
                      type="number"
                      className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-accent-blue"
                      value={inputs.averageMinutesPerCall}
                      onChange={(e) => handleInputChange('averageMinutesPerCall', Number(e.target.value))}
                      aria-label="Average minutes per call"
                    />
                  </div>
                </div>
              )}
              {inputs.serviceType === 'chatbot' && (
                <div>
                  <div className="flex items-center gap-2 mb-2">
                    <label htmlFor="peakConcurrentChats" className="text-white">Peak Concurrent Chats</label>
                    <div className="relative group">
                      <span className="cursor-help text-white/60">!</span>
                      <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 p-2 bg-black/90 rounded-lg text-sm text-white/80 w-64 hidden group-hover:block">
                        First 40 concurrent chats are free. Each additional concurrent chat costs $10/month.
                      </div>
                    </div>
                  </div>
                  <input
                    id="peakConcurrentChats"
                    type="number"
                    className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-2 text-white focus:border-accent-blue focus:outline-none"
                    value={inputs.peakConcurrentChats}
                    onChange={(e) => handleInputChange('peakConcurrentChats', Math.max(40, parseInt(e.target.value) || 40))}
                    aria-label="Peak concurrent chats"
                    min="40"
                  />
                  {inputs.peakConcurrentChats > 40 && (
                    <p className="mt-1 text-sm text-white/60">
                      ${(inputs.peakConcurrentChats - 40) * 10}/month ({inputs.peakConcurrentChats - 40} additional lines at $10/month)
                    </p>
                  )}
                </div>
              )}

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={calculateROI}
                disabled={!inputs.serviceType}
                className={`w-full py-3 rounded-lg text-white font-medium shadow-lg hover:shadow-xl transition-shadow ${
                  inputs.serviceType
                    ? 'bg-gradient-to-r from-accent-blue via-accent-purple to-accent-pink'
                    : 'bg-gray-500 cursor-not-allowed'
                }`}
                aria-label="Calculate ROI"
              >
                Calculate ROI
              </motion.button>
            </div>
          </motion.div>

          {/* Results Display */}
          <motion.div
            className="space-y-6"
            initial={{ opacity: 0, x: 20 }}
            animate={isInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {showResults && results && (
              <div className="bg-white/5 backdrop-blur-lg rounded-xl border border-white/10 p-6">
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <h3 className="text-2xl font-display font-bold text-white mb-2">Your Results</h3>
                    <p className="text-white/70">Based on your input, here's what you could save:</p>
                  </div>

                  <div className="mb-8">
                    <h3 className="text-xl font-display font-bold mb-4">Monthly Cost Breakdown</h3>
                    <div className="text-4xl font-bold text-accent-blue mb-2">
                      ${Number(results.newMonthlyCost).toLocaleString()}
                    </div>
                    <div className="space-y-2 text-sm text-white/60">
                      <div>{results.volumeMetrics} available</div>
                      {inputs.serviceType === 'voice' && (
                        <div>
                          <div>Base service cost: ${Number(results.newMonthlyCost - results.concurrencyFee).toLocaleString()}</div>
                          <div>Concurrent lines cost: ${results.concurrencyFee} ({inputs.peakConcurrentCalls} lines at $11/month)</div>
                        </div>
                      )}
                      {inputs.serviceType === 'chatbot' && (
                        <div>
                          <div>Base service cost: ${Number(results.newMonthlyCost - (inputs.peakConcurrentChats > 40 ? (inputs.peakConcurrentChats - 40) * 10 : 0)).toLocaleString()}</div>
                          <div>Concurrent chats cost: {(inputs.peakConcurrentChats > 40 ? (inputs.peakConcurrentChats - 40) * 10 : 0)} ({inputs.peakConcurrentChats > 40 ? inputs.peakConcurrentChats - 40 : 0} additional lines at $10/month)</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="space-y-2">
                    <p className="text-white/70">First Month Total</p>
                    <div className="space-y-1">
                      <p className="text-3xl font-display font-bold text-accent-pink">
                        ${(Number(results.setupFee) + Number(results.newMonthlyCost)).toLocaleString()}
                      </p>
                      <p className="text-sm text-white/60">
                        (${Number(results.setupFee).toLocaleString()} setup + ${Number(results.newMonthlyCost).toLocaleString()} monthly)
                      </p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <p className="text-white/70">Current Monthly Cost</p>
                    <div className="space-y-1">
                      <p className="text-3xl font-display font-bold text-accent-blue">
                        ${Number(results.currentMonthlyCost).toLocaleString()}
                      </p>
                      <p className="text-sm text-white/60">
                        ${Number(results.currentMonthlyCost * 12).toLocaleString()} annually
                      </p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <p className="text-white/70">Monthly Savings</p>
                    <div className="space-y-1">
                      <p className="text-3xl font-display font-bold text-accent-purple">
                        ${Number(results.monthlySavings).toLocaleString()}
                      </p>
                      <p className="text-sm text-white/60">
                        ${Number(results.monthlySavings * 12).toLocaleString()} annually
                      </p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <p className="text-xl font-semibold text-accent-green">Yearly ROI</p>
                    <div className="space-y-1">
                      <p className="text-4xl font-display font-bold bg-gradient-to-r from-accent-blue via-accent-purple to-accent-pink bg-clip-text text-transparent">
                        ${Number(results.yearlyROI).toLocaleString()}
                      </p>
                      <p className="text-sm text-white/60">
                        (${Number(results.yearlyGrossSavings).toLocaleString()} savings - ${Number(results.setupFee).toLocaleString()} setup)
                      </p>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <p className="text-white/70">Payback Period</p>
                    <p className="text-3xl font-display font-bold text-accent-pink">
                      {results.paybackPeriodMonths} months
                    </p>
                  </div>

                  <div className="space-y-2">
                    <p className="text-white/70">Volume Details</p>
                    <p className="text-xl font-display text-accent-blue">
                      {results.volumeMetrics}
                    </p>
                  </div>

                  <div className="col-span-2 space-y-2">
                    <p className="text-white/70">Ready to transform your business with {inputs.serviceType === 'voice' ? 'AI Voice Agents' : 'AI Chatbots'}?</p>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => navigate('/book-call')}
                      className="w-full py-3 bg-gradient-to-r from-accent-blue via-accent-purple to-accent-pink rounded-lg text-white font-medium shadow-lg hover:shadow-xl transition-shadow"
                      aria-label="Book a call with an expert"
                    >
                      Book a Call with Expert
                    </motion.button>
                  </div>
                </div>

                <div className="mt-8 pt-6 border-t border-white/10">
                </div>
              </div>
            )}

            {(!showResults || !results) && (
              <div className="bg-white/5 backdrop-blur-lg rounded-xl border border-white/10 p-6 h-full flex items-center justify-center">
                <div className="text-center">
                  <p className="text-white/70 mb-4">
                    {!inputs.serviceType 
                      ? 'Select a service to begin'
                      : 'Enter your details to see potential savings'}
                  </p>
                  <svg
                    className="w-12 h-12 mx-auto text-accent-blue animate-bounce"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 14l-7 7m0 0l-7-7m7 7V3"
                    />
                  </svg>
                </div>
              </div>
            )}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ROICalculator;
