import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { FaPhoneAlt, FaRobot, FaBuilding, FaHospital, FaUtensils, FaTruck, FaGraduationCap, 
  FaHeadset, FaMicrophone, FaUserTie, FaChartLine, FaHome, FaIndustry, FaCar, FaMicrochip,
  FaArrowRight, FaPlay, FaCheckCircle, FaConciergeBell, FaCalendarCheck, FaMoneyBillWave, FaChartBar, 
  FaLanguage, FaRocket, FaBrain, FaExpandAlt, FaTrophy, FaUserPlus, FaCogs, FaComments, FaMobile, 
  FaUsers, FaCalendar, FaEnvelope, FaCalendarAlt, FaVoicemail, FaFacebookMessenger, FaMagic, FaChevronDown, FaShoppingCart, FaFunnelDollar, FaLifeRing } from 'react-icons/fa';
import { HiOutlinePhone, HiSparkles } from 'react-icons/hi';
import { BsFillChatDotsFill, BsArrowRightShort } from 'react-icons/bs';

interface UseCaseCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  features: string[];
  benefits: string[];
  cta?: string;
  delay?: number;
  onHover?: () => void;
}

interface CategoryProps {
  id: string;
  title: string;
  icon: React.ReactNode;
  description: string;
  cases: {
    title: string;
    description: string;
    icon: React.ReactNode;
    features: string[];
    benefits: string[];
    cta?: string;
  }[];
}

const UseCaseCard: React.FC<UseCaseCardProps> = ({ title, description, icon, features, benefits, cta = "Learn More", delay = 0, onHover }) => {
  const controls = useAnimation();
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;
  const [isHovered, setIsHovered] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.style.scrollBehavior === 'smooth') {
        setIsScrolling(true);
        setIsHovered(false);
        setTimeout(() => setIsScrolling(false), 1000);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <motion.div
      animate={controls}
      className="group relative bg-gradient-to-br from-black/40 to-black/20 backdrop-blur-xl border border-white/10 rounded-2xl p-6 hover:border-accent-blue/50 transition-all duration-300 overflow-hidden cursor-pointer"
      style={{ 
        height: isMobile ? 'auto' : (isHovered ? 'auto' : '180px'),
        position: 'relative',
        zIndex: isHovered ? 10 : 1,
      }}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay }}
      onHoverStart={() => {
        if (!isMobile && !isScrolling) {
          setIsHovered(true);
          onHover?.();
          controls.start({ scale: 1.02 });
        }
      }}
      onHoverEnd={() => {
        if (!isMobile) {
          setIsHovered(false);
          controls.start({ scale: 1 });
        }
      }}
    >
      {/* Touch device support */}
      {isMobile && (
        <button 
          className="absolute inset-0 w-full h-full z-10"
          onClick={() => setIsHovered(!isHovered)}
          aria-label="Toggle card details"
        />
      )}

      {/* Background Glow Effect */}
      <div className="absolute inset-0 bg-gradient-to-br from-accent-blue/0 via-accent-purple/0 to-accent-pink/0 group-hover:from-accent-blue/10 group-hover:via-accent-purple/10 group-hover:to-accent-pink/10 transition-all duration-500" />
      
      {/* Content */}
      <div className="relative">
        {/* Icon and Title */}
        <div className="flex items-start gap-4 mb-4">
          <motion.div 
            className="text-accent-blue text-2xl p-3 rounded-xl bg-accent-blue/10 backdrop-blur-sm"
            animate={{ rotate: isHovered ? 360 : 0 }}
            transition={{ duration: 0.5 }}
          >
            {icon}
          </motion.div>
          <div>
            <h3 className="text-xl font-display font-bold text-white mb-2 group-hover:text-accent-blue transition-colors">
              {title}
            </h3>
            <p className="text-white/60 group-hover:text-white/80 transition-colors">
              {description}
            </p>
          </div>
        </div>

        {/* Features and Benefits */}
        <AnimatePresence>
          {(isHovered || isMobile) && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="space-y-4 mt-4 pt-4 border-t border-white/10"
            >
              {/* Features */}
              <div>
                <h4 className="text-sm font-semibold text-accent-blue mb-2">Key Features</h4>
                <ul className="space-y-2">
                  {features.map((feature, index) => (
                    <motion.li
                      key={feature}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="flex items-center gap-2 text-sm text-white/70"
                    >
                      <FaCheckCircle className="text-accent-blue/70" />
                      {feature}
                    </motion.li>
                  ))}
                </ul>
              </div>

              {/* Benefits */}
              <div>
                <h4 className="text-sm font-semibold text-accent-purple mb-2">Benefits</h4>
                <ul className="space-y-2">
                  {benefits.map((benefit, index) => (
                    <motion.li
                      key={benefit}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.2 + index * 0.1 }}
                      className="flex items-center gap-2 text-sm text-white/70"
                    >
                      <HiSparkles className="text-accent-purple/70" />
                      {benefit}
                    </motion.li>
                  ))}
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const ServiceSection: React.FC<{
  title: string;
  icon: React.ReactNode;
  description: string;
  isActive: boolean;
  onClick: () => void;
}> = ({ title, icon, description, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-2 px-2 py-1.5 rounded-lg transition-all ${
      isActive
        ? 'bg-accent-blue text-white'
        : 'bg-black/20 text-white/70 hover:bg-black/30'
    }`}
  >
    <div className="text-base">{icon}</div>
    <div className="text-left">
      <h3 className="font-display font-medium text-xs leading-tight">{title}</h3>
      <p className="text-[10px] opacity-80 leading-tight">{description}</p>
    </div>
  </button>
);

type UseCaseType = 'voice' | 'chatbot' | 'crm';
type SubUseCaseType = 'inbound' | 'outbound' | 'webai' | 'products' | 'iot';

const UseCases = () => {
  const [activeUseCase, setActiveUseCase] = React.useState<UseCaseType>('voice');
  const [activeSubUseCase, setActiveSubUseCase] = React.useState<SubUseCaseType>('inbound');
  const [isScrolling, setIsScrolling] = React.useState(false);
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

  const handleUseCaseClick = (useCase: string) => {
    setActiveUseCase(useCase as UseCaseType);
  };

  const handleSubUseCaseClick = (subUseCase: string) => {
    setActiveSubUseCase(subUseCase as SubUseCaseType);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.style.scrollBehavior === 'smooth') {
        setIsScrolling(true);
        setTimeout(() => setIsScrolling(false), 1000);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const voiceCategories: CategoryProps[] = [
    {
      id: 'inbound',
      title: 'Inbound Calls',
      icon: <FaPhoneAlt />,
      description: 'Transform your customer service with AI-powered call handling',
      cases: [
        {
          title: 'E-commerce Support',
          description: 'Intelligent shopping assistance and order management',
          icon: <FaShoppingCart />,
          features: ['Order tracking', 'Product recommendations', 'Return processing', 'Inventory checks'],
          benefits: ['Increase sales by 40%', 'Reduce cart abandonment', 'Improve customer satisfaction']
        },
        {
          title: 'Restaurant Booking',
          description: 'Streamline reservations and orders with AI voice assistance',
          icon: <FaUtensils />,
          features: ['24/7 reservation management', 'Real-time availability updates', 'Multi-language support', 'Order processing'],
          benefits: ['Reduce missed bookings by 95%', 'Handle 500+ calls simultaneously', 'Increase customer satisfaction']
        },
        {
          title: 'Healthcare Scheduling',
          description: 'Efficient appointment management and patient support',
          icon: <FaHospital />,
          features: ['Automated scheduling', 'Insurance verification', 'Appointment reminders', 'Emergency triage'],
          benefits: ['Reduce wait times by 60%', 'Lower no-show rates', 'Improve patient experience']
        },
        {
          title: 'Technical Support',
          description: 'Instant IT and product support resolution',
          icon: <FaHeadset />,
          features: ['Problem diagnosis', 'Step-by-step troubleshooting', 'Ticket creation', 'Knowledge base integration'],
          benefits: ['85% first-call resolution', 'Reduce support costs', '24/7 availability']
        },
        {
          title: 'Hotel Concierge',
          description: 'Luxury hotel experience with AI assistance',
          icon: <FaConciergeBell />,
          features: ['Room service ordering', 'Local recommendations', 'Facility booking', 'Guest requests'],
          benefits: ['Enhanced guest satisfaction', 'Reduced staff workload', 'Increased service efficiency']
        }
      ]
    },
    {
      id: 'outbound',
      title: 'Outbound Calls',
      icon: <HiOutlinePhone />,
      description: 'Proactive engagement that drives results',
      cases: [
        {
          title: 'E-commerce Outreach',
          description: 'Proactive sales and customer retention',
          icon: <FaShoppingCart />,
          features: ['Abandoned cart recovery', 'Personalized promotions', 'Reorder reminders', 'Customer win-back'],
          benefits: ['30% cart recovery rate', 'Increased customer lifetime value', 'Higher repeat purchases']
        },
        {
          title: 'Lead Qualification',
          description: 'Convert more leads with intelligent qualification',
          icon: <FaBuilding />,
          features: ['Smart lead scoring', 'Meeting scheduling', 'CRM integration', 'Follow-up automation'],
          benefits: ['3x faster qualification', 'Higher conversion rates', 'Reduced cost per lead']
        },
        {
          title: 'Appointment Reminders',
          description: 'Reduce no-shows with smart scheduling',
          icon: <FaCalendarCheck />,
          features: ['Automated confirmations', 'Rescheduling options', 'Multi-channel notifications', 'Calendar sync'],
          benefits: ['80% reduction in no-shows', 'Improved scheduling efficiency', 'Better resource utilization']
        },
        {
          title: 'Payment Collections',
          description: 'Streamline debt recovery with AI assistance',
          icon: <FaMoneyBillWave />,
          features: ['Payment plan setup', 'Automated follow-ups', 'Compliance recording', 'Payment processing'],
          benefits: ['Increase collection rates', 'Reduce operational costs', 'Maintain compliance']
        },
        {
          title: 'Customer Surveys',
          description: 'Gather insights with intelligent feedback collection',
          icon: <FaChartBar />,
          features: ['Dynamic questioning', 'Sentiment analysis', 'Real-time reporting', 'Follow-up triggers'],
          benefits: ['Higher response rates', 'Deeper customer insights', 'Actionable feedback']
        }
      ]
    },
    {
      id: 'webai',
      title: 'Web AI Agent',
      icon: <FaRobot />,
      description: 'Intelligent web assistance with voice capabilities',
      cases: [
        {
          title: 'Sales Funnel Agent',
          description: 'Intelligent lead qualification and conversion',
          icon: <FaFunnelDollar />,
          features: ['Lead scoring', 'Qualification questions', 'Meeting scheduling', 'Deal progression'],
          benefits: ['2x higher conversion rates', 'Faster sales cycle', 'Qualified leads 24/7']
        },
        {
          title: 'Support Agent',
          description: 'Interactive help and customer assistance',
          icon: <FaLifeRing />,
          features: ['Smart FAQ handling', 'Product guidance', 'Issue resolution', 'Knowledge base integration'],
          benefits: ['90% self-service rate', 'Reduced support costs', 'Instant response times']
        },
        {
          title: 'Landing Page Agent',
          description: 'Voice-enabled conversion optimization',
          icon: <FaRocket />,
          features: ['Voice interactions', 'Personalized pitches', 'Real-time objection handling', 'Smooth handoff to sales'],
          benefits: ['40% higher conversion', 'Increased engagement', 'Better qualified leads']
        }
      ]
    },
    {
      id: 'products',
      title: 'Voice Products',
      icon: <FaMicrophone />,
      description: 'Advanced voice AI solutions for every need',
      cases: [
        {
          title: 'Sales Training',
          description: 'Level up your sales team with AI coaching',
          icon: <FaGraduationCap />,
          features: ['Role-play scenarios', 'Real-time feedback', 'Performance analytics', 'Customized training'],
          benefits: ['Faster onboarding', 'Improved close rates', 'Consistent training quality']
        },
        {
          title: 'Interview Practice',
          description: 'Perfect interview skills with AI feedback',
          icon: <FaUserTie />,
          features: ['Industry-specific questions', 'Answer analysis', 'Body language tips', 'Confidence building'],
          benefits: ['Better interview performance', 'Reduced anxiety', 'Professional growth']
        },
        {
          title: 'Voice Analytics',
          description: 'Transform conversations into actionable insights',
          icon: <FaChartLine />,
          features: ['Sentiment tracking', 'Keyword spotting', 'Compliance monitoring', 'Trend analysis'],
          benefits: ['Improved customer satisfaction', 'Risk mitigation', 'Data-driven decisions']
        },
        {
          title: 'Language Learning',
          description: 'Master new languages with AI conversation practice',
          icon: <FaLanguage />,
          features: ['Native speaker simulation', 'Pronunciation feedback', 'Vocabulary building', 'Progress tracking'],
          benefits: ['Faster language acquisition', 'Confidence in speaking', 'Personalized learning']
        }
      ]
    },
    {
      id: 'iot',
      title: 'Voice IoT',
      icon: <FaMicrochip />,
      description: 'Connect your devices with intelligent voice control',
      cases: [
        {
          title: 'Smart Manufacturing',
          description: 'Voice-controlled industrial automation',
          icon: <FaIndustry />,
          features: ['Equipment control', 'Status monitoring', 'Maintenance alerts', 'Safety protocols'],
          benefits: ['Increased productivity', 'Reduced downtime', 'Enhanced safety']
        },
        {
          title: 'Connected Vehicles',
          description: 'Next-gen automotive voice assistance',
          icon: <FaCar />,
          features: ['Navigation control', 'Vehicle diagnostics', 'Entertainment system', 'Climate control'],
          benefits: ['Safer driving', 'Enhanced comfort', 'Better maintenance']
        },
        {
          title: 'Smart Buildings',
          description: 'Intelligent building management with voice',
          icon: <FaBuilding />,
          features: ['Climate control', 'Security management', 'Visitor access', 'Energy optimization'],
          benefits: ['Energy savings', 'Improved security', 'Better comfort']
        },
        {
          title: 'Healthcare IoT',
          description: 'Voice-enabled medical device control',
          icon: <FaHospital />,
          features: ['Equipment control', 'Patient monitoring', 'Emergency alerts', 'Data logging'],
          benefits: ['Improved patient care', 'Reduced errors', 'Better outcomes']
        }
      ]
    }
  ];

  const chatbotUseCases = [
    {
      title: 'E-commerce Assistant',
      description: 'Smart shopping guidance and support',
      icon: <FaShoppingCart />,
      features: ['Product search & filtering', 'Shopping cart assistance', 'Personalized recommendations', 'Order status tracking'],
      benefits: ['Higher conversion rates', 'Reduced cart abandonment', 'Personalized shopping experience']
    },
    {
      title: 'Customer Support',
      description: 'Instant responses to common questions and issues',
      icon: <FaHeadset />,
      features: ['Multi-channel support', 'Knowledge base'],
      benefits: ['Increased customer satisfaction', 'Reduced support tickets']
    },
    {
      title: 'Product Information',
      description: 'Detailed product specs, pricing, and availability',
      icon: <FaBuilding />,
      features: ['Product catalog', 'Real-time inventory'],
      benefits: ['Improved customer satisfaction', 'Increased sales']
    },
    {
      title: 'Service Booking',
      description: 'Appointment scheduling and reservation management',
      icon: <FaUtensils />,
      features: ['Appointment scheduling', 'Reservation management'],
      benefits: ['Improved customer satisfaction', 'Increased efficiency']
    }
  ];

  const services = ['voice', 'chatbot', 'crm'];

  const currentUseCases = activeUseCase === 'voice'
    ? voiceCategories.find(c => c.id === activeSubUseCase)?.cases
    : activeUseCase === 'crm'
    ? [
        {
          title: 'Sales Pipelines',
          description: 'Create Unlimited Pipelines',
          icon: <FaChartBar />,
          features: [
            'Multiple pipeline management',
            'Deal tracking & stages',
            'Sales forecasting',
            'Pipeline analytics'
          ],
          benefits: [
            'Streamline sales process',
            'Track deals effectively',
            'Increase close rates'
          ]
        },
        {
          title: 'Funnels & Website Builder',
          description: 'Build Unlimited Funnels',
          icon: <FaCogs />,
          features: [
            'Drag-and-drop builder',
            'Conversion optimization',
            'Mobile responsive design',
            'Custom domains'
          ],
          benefits: [
            'Create high-converting funnels',
            'Professional websites instantly',
            'Boost conversion rates'
          ]
        },
        {
          title: 'Workflow Automation',
          description: 'Workflow Management Software',
          icon: <FaCogs />,
          features: [
            'Visual workflow builder',
            'Task automation',
            'Process optimization',
            'Custom triggers'
          ],
          benefits: [
            'Save time on repetitive tasks',
            'Increase team efficiency',
            'Scale operations seamlessly'
          ]
        },
        {
          title: 'Inbound & Outbound Calls',
          description: 'Make & Receive Unlimited Calls',
          icon: <FaPhoneAlt />,
          features: [
            'Call tracking & recording',
            'Auto-dialer system',
            'Call analytics',
            'Voice broadcasting'
          ],
          benefits: [
            'Professional call handling',
            'Increase call efficiency',
            'Complete call management'
          ]
        },
        {
          title: 'Course Builder',
          description: 'Grow Your Memberships',
          icon: <FaGraduationCap />,
          features: [
            'Course creation tools',
            'Member management',
            'Content delivery',
            'Progress tracking'
          ],
          benefits: [
            'Create engaging courses',
            'Manage memberships easily',
            'Scale your education business'
          ]
        },
        {
          title: 'Lead Sources',
          description: 'Integrate Your Lead Sources',
          icon: <FaUsers />,
          features: [
            'Multi-source integration',
            'Lead tracking',
            'Source analytics',
            'Automated distribution'
          ],
          benefits: [
            'Centralize lead management',
            'Track source performance',
            'Optimize lead generation'
          ]
        },
        {
          title: 'Inbound & Outbound SMS',
          description: 'Text Blast Services',
          icon: <FaComments />,
          features: [
            'Two-way texting',
            'Mass text campaigns',
            'SMS automation',
            'Message scheduling'
          ],
          benefits: [
            'Engage customers instantly',
            'High open rates',
            'Direct communication'
          ]
        },
        {
          title: 'Social Media Scheduler',
          description: 'Automating Your Social Media',
          icon: <FaCalendar />,
          features: [
            'Content scheduling',
            'Multi-platform posting',
            'Analytics tracking',
            'Content calendar'
          ],
          benefits: [
            'Save time on posting',
            'Maintain consistent presence',
            'Track social performance'
          ]
        },
        {
          title: 'Text/Email Blasts',
          description: 'Email and SMS Marketing',
          icon: <FaEnvelope />,
          features: [
            'Campaign builder',
            'List segmentation',
            'Performance analytics',
            'A/B testing'
          ],
          benefits: [
            'Reach customers effectively',
            'Increase engagement',
            'Drive more conversions'
          ]
        },
        {
          title: 'Mobile App',
          description: 'iOS on Android Mobile App',
          icon: <FaMobile />,
          features: [
            'Cross-platform support',
            'Real-time notifications',
            'Mobile CRM access',
            'On-the-go management'
          ],
          benefits: [
            'Manage business anywhere',
            'Stay connected always',
            'Increase productivity'
          ]
        },
        {
          title: 'Team Calendars',
          description: 'Build Unlimited Calendars',
          icon: <FaCalendarAlt />,
          features: [
            'Team scheduling',
            'Appointment booking',
            'Calendar sync',
            'Availability management'
          ],
          benefits: [
            'Streamline scheduling',
            'Reduce booking conflicts',
            'Improve team coordination'
          ]
        },
        {
          title: 'Ringless Voicemail Drops',
          description: 'Twilio Compliant Solution',
          icon: <FaVoicemail />,
          features: [
            'Direct voicemail delivery',
            'Campaign management',
            'Performance tracking',
            'Automated drops'
          ],
          benefits: [
            'Non-intrusive outreach',
            'High delivery rates',
            'Cost-effective communication'
          ]
        },
        {
          title: 'AI Sales Bot',
          description: 'ChatBot for Sales Automation',
          icon: <FaRobot />,
          features: [
            'Intelligent responses',
            'Lead qualification',
            '24/7 availability',
            'Seamless handoff'
          ],
          benefits: [
            'Automate sales processes',
            'Instant lead engagement',
            'Increase conversion rates'
          ]
        },
        {
          title: 'FB, IG & WhatsApp Msging',
          description: 'Social Media Messaging Hub',
          icon: <FaFacebookMessenger />,
          features: [
            'Multi-platform inbox',
            'Automated responses',
            'Message management',
            'Campaign automation'
          ],
          benefits: [
            'Centralize social messaging',
            'Faster response times',
            'Improved engagement'
          ]
        },
        {
          title: 'AI Content Generator',
          description: 'An All-In-One Content Solution',
          icon: <FaMagic />,
          features: [
            'AI-powered content creation',
            'Multiple content types',
            'Brand voice customization',
            'Content optimization'
          ],
          benefits: [
            'Save time on content',
            'Maintain consistency',
            'Scale content production'
          ]
        }
      ]
    : chatbotUseCases;

  return (
    <section id="use-cases" className="py-24 relative overflow-hidden bg-black">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-accent-blue/5 to-black" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-accent-purple/10 via-transparent to-transparent" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Section Header */}
        <motion.div
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl md:text-5xl font-display font-bold mb-4">
            <span className="bg-gradient-to-r from-accent-blue via-accent-purple to-accent-pink text-transparent bg-clip-text">
              Use Cases
            </span>
          </h2>
          <p className="text-white/70 max-w-2xl mx-auto">
            Discover how our AI solutions can transform your business operations across different industries
          </p>
        </motion.div>

        {/* Service Toggle Buttons */}
        <div className="flex flex-col items-center mb-12">
          <motion.div 
            className="flex flex-wrap justify-center gap-4 p-2 rounded-2xl bg-white/5 backdrop-blur-sm border border-white/10"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {services.map(service => (
              <motion.button
                key={service}
                onClick={() => handleUseCaseClick(service)}
                className={`relative px-6 py-3 rounded-xl transition-all duration-300 ${
                  activeUseCase === service 
                    ? 'bg-accent-blue text-white shadow-lg shadow-accent-blue/20' 
                    : 'text-white/60 hover:text-white'
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <div className="relative flex items-center gap-3">
                  <motion.span 
                    className="text-2xl"
                    animate={{ scale: activeUseCase === service ? 1.05 : 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {service === 'voice' ? <HiOutlinePhone /> : 
                     service === 'chatbot' ? <BsFillChatDotsFill /> :
                     <FaRocket />}
                  </motion.span>
                  <span className="font-medium">
                    {service === 'voice' ? 'Voice AI' :
                     service === 'chatbot' ? 'AI Chat Agent' :
                     'AI CRM'}
                  </span>
                </div>
              </motion.button>
            ))}
          </motion.div>
        </div>

        {/* Use Cases Grid */}
        <div className={`grid gap-4 sm:gap-6 md:gap-8 
          ${activeUseCase === 'crm' 
            ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4' 
            : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'}`}
        >
          {/* Category Tabs for Voice AI */}
          {activeUseCase === 'voice' && (
            <div className="col-span-full mb-8">
              <div className="flex flex-wrap justify-center gap-4">
                {voiceCategories.map(category => (
                  <motion.button
                    key={category.id}
                    onClick={() => handleSubUseCaseClick(category.id)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-300 ${
                      activeSubUseCase === category.id
                        ? 'bg-accent-blue text-white'
                        : 'bg-white/5 text-white/60 hover:bg-white/10 hover:text-white'
                    }`}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <span className="flex items-center gap-2">
                      {category.icon}
                      {category.title}
                    </span>
                  </motion.button>
                ))}
              </div>
            </div>
          )}

          {/* Use Case Cards */}
          <AnimatePresence mode="wait">
            <motion.div 
              className="contents"
              key={activeUseCase + activeSubUseCase}
              initial="hidden"
              animate="show"
              exit="hidden"
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                  transition: {
                    staggerChildren: 0.1
                  }
                }
              }}
            >
              {currentUseCases?.map((useCase, index) => (
                <motion.div
                  key={useCase.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="bg-white/5 backdrop-blur-sm rounded-xl p-6 hover:bg-white/10 transition-colors"
                >
                  <h3 className="text-xl font-semibold mb-4">{useCase.title}</h3>
                  <p className="text-white/60">{useCase.description}</p>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <style dangerouslySetInnerHTML={{
        __html: `
          .gradient-border {
            position: relative;
            border-radius: 0.5rem;
            background: linear-gradient(45deg, #00A3FF, #7000FF, #FF00E5);
            padding: 1px;
          }
          .gradient-border::before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 0.5rem;
            padding: 1px;
            background: linear-gradient(45deg, #00A3FF, #7000FF, #FF00E5);
            -webkit-mask: 
              linear-gradient(#fff 0 0) content-box, 
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
          }
        `
      }} />
    </section>
  );
};

export default UseCases;
