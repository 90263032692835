import { useRef, useMemo, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Vector2, ShaderMaterial, Color } from 'three';
import type { FC } from 'react';

const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const fragmentShader = `
  uniform float time;
  uniform vec2 resolution;
  uniform vec3 colorA;
  uniform vec3 colorB;
  uniform vec3 colorC;
  varying vec2 vUv;

  // Optimized noise
  vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
  vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
  vec3 permute(vec3 x) { return mod289(((x*34.0)+1.0)*x); }

  float snoise(vec2 v) {
    const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                        0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                        -0.577350269189626,  // -1.0 + 2.0 * C.x
                        0.024390243902439); // 1.0 / 41.0
    vec2 i  = floor(v + dot(v, C.yy) );
    vec2 x0 = v -   i + dot(i, C.xx);
    vec2 i1;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;
    i = mod289(i);
    vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
      + i.x + vec3(0.0, i1.x, 1.0 ));
    vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
    m = m*m ;
    m = m*m ;
    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;
    m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
    vec3 g;
    g.x  = a0.x  * x0.x  + h.x  * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
  }

  void main() {
    vec2 uv = vUv;
    vec2 pos = uv * 2.0 - 1.0;
    
    // Darker base color
    vec3 color = vec3(0.02, 0.02, 0.04);
    
    // Keep only blue wave movement
    float t = time * 0.2;
    vec2 movement1 = vec2(t * 0.1, sin(t) * 0.1);
    vec2 movement2 = vec2(cos(t) * 0.1, t * 0.15);
    
    float n1 = snoise((uv + movement1) * 3.0);
    float n2 = snoise((uv - movement2) * 2.0);
    
    // Only blue color mixing
    color = mix(color, colorA * 0.7, smoothstep(0.3, 0.7, n1) * 0.8);
    color = mix(color, colorA * 0.4, smoothstep(0.4, 0.6, n2) * 0.6);
    
    // Subtle blue glow
    float glow = pow(1.0 - length(pos), 2.0) * 0.6;
    color += glow * colorA * 0.2;
    
    // Vignette
    float vignette = pow(1.0 - length(pos + sin(time * 0.2) * 0.1), 1.8);
    color *= vignette;
    
    gl_FragColor = vec4(color, 1.0);
  }
`;

function BackgroundShader() {
  const meshRef = useRef<THREE.Mesh>(null);
  const { viewport } = useThree();
  const frameCount = useRef(0);

  useFrame(({ clock, size }) => {
    if (!meshRef.current) return;
    
    const material = meshRef.current.material as ShaderMaterial;
    if (!material || !material.uniforms) return;

    material.uniforms.time.value = clock.getElapsedTime();
    material.uniforms.resolution.value.set(size.width, size.height);
  });

  return (
    <mesh ref={meshRef}>
      <planeGeometry args={[viewport.width, viewport.height]} />
      <shaderMaterial 
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        uniforms={{
          time: { value: 0 },
          resolution: { value: new Vector2() },
          colorA: { value: new Color('#00A3FF') },
          colorB: { value: new Color('#00A3FF') },
          colorC: { value: new Color('#00A3FF') }
        }}
      />
    </mesh>
  );
}

const ElegantBackground: FC = () => {
  const dpr = typeof window !== 'undefined' ? Math.min(1.5, window.devicePixelRatio) : 1;

  return (
    <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
      <Canvas camera={{ position: [0, 0, 5] }} dpr={dpr}>
        <BackgroundShader />
      </Canvas>
    </div>
  );
};

export default ElegantBackground;
