import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import BookCall from './pages/BookCall';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import CookieConsent from './components/CookieConsent';

import type { ReactElement } from 'react';

function App(): ReactElement {
  useEffect(() => {
    // Disable scrolling when modal is open
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        const modals = document.querySelectorAll('[role="dialog"]');
        modals.forEach(modal => {
          if (modal instanceof HTMLElement) {
            modal.style.display = 'none';
          }
        });
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-[#0A0A0A] text-white relative overflow-hidden">
        <CookieConsent />
        {/* Global animated gradient background */}
        <div className="gradient-bg fixed inset-0 opacity-20" />
        
        {/* Smooth transition overlays */}
        <div className="fixed inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-transparent via-black/50 to-black pointer-events-none" />
        <div className="fixed inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-40 pointer-events-none" />
        
        {/* Main content */}
        <div className="relative z-10">
          <Navbar />
          <Routes>
            <Route 
              path="/" 
              element={
                <Home />
              } 
            />
            <Route 
              path="/book-call"
              element={
                <BookCall />
              }
            />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
