import React, { useEffect } from 'react';

const BookCall: React.FC = () => {
  useEffect(() => {
    // Load Calendly widget script
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-black text-white pt-4">
      <div className="container mx-auto px-4">
        <div 
          className="calendly-inline-widget mx-auto rounded-lg shadow-xl overflow-hidden bg-white/5 backdrop-blur-sm"
          data-url="https://calendly.com/nathan-syncramatics/30min"
          style={{ minWidth: '320px', height: '700px' }}
        />
      </div>
    </div>
  );
};

export default BookCall;
