import Hero from '../components/Hero';
import Solutions from '../components/Solutions';
import ROICalculator from '../components/ROICalculator';
import WhyChoose from '../components/WhyChoose';
import Contact from '../components/Contact';
import UseCases from '../components/UseCases';
import What from '../components/What';

function Home() {
  return (
    <main className="relative">
      <div className="absolute inset-0 bg-gradient-to-b from-accent-blue/5 via-accent-purple/5 to-accent-pink/5 opacity-20 pointer-events-none" />
      <Hero />
      <What />
      <UseCases />
      <Solutions />
      <WhyChoose />
      <ROICalculator />
      <Contact />
    </main>
  );
}

export default Home;
