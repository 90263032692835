import { FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import Logo from './Logo';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#0D0D0D] text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo and Info */}
          <div className="col-span-1">
            <Logo />
            <p className="mt-4 text-sm text-gray-400">
              &copy; {currentYear} SyncraMatic. All rights reserved.
            </p>
          </div>

          {/* Contact Info */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4">CONTACT</h3>
            <a href="mailto:info@syncramatic.com" className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors mb-2">
              <MdEmail />
              info@syncramatic.com
            </a>
            <a href="tel:+1(816)772-2803" className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors mb-2">
              <FaPhone />
              +1 (816) 772-2803
            </a>
            <div className="flex items-center gap-2 text-gray-400">
              <FaMapMarkerAlt />
              Dallas, Texas
            </div>
          </div>

          {/* Hours */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4">HOURS</h3>
            <div className="text-gray-400">
              <p className="mb-2">Monday - Friday</p>
              <p className="mb-4">9:00 AM - 6:00 PM PST</p>
              <p className="mb-2">Saturday - Sunday</p>
              <p>Closed</p>
            </div>
          </div>

          {/* Legal */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4">LEGAL</h3>
            <a href="/privacy" className="block text-gray-400 hover:text-white transition-colors mb-2">
              Privacy Notice
            </a>
            <a href="/terms" className="block text-gray-400 hover:text-white transition-colors">
              Terms of Service
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
