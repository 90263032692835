import React, { useState } from 'react';
import CookieConsentBanner from 'react-cookie-consent';

// Add type declaration for gtag
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

interface CookieSettings {
  necessary: boolean;
  analytics: boolean;
  marketing: boolean;
}

const CookieConsent: React.FC = () => {
  const [showSettings, setShowSettings] = useState(false);
  // Default all optional cookies to false for GDPR compliance
  const [settings, setSettings] = useState<CookieSettings>({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  const handleAcceptAll = () => {
    setSettings({
      necessary: true,
      analytics: true,
      marketing: true,
    });
    localStorage.setItem('cookieConsent', JSON.stringify({
      necessary: true,
      analytics: true,
      marketing: true,
      timestamp: new Date().toISOString(),
    }));
    if (window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': 'granted',
        'ad_storage': 'granted'
      });
    }
  };

  const handleRejectAll = () => {
    setSettings({
      necessary: true,
      analytics: false,
      marketing: false,
    });
    localStorage.setItem('cookieConsent', JSON.stringify({
      necessary: true,
      analytics: false,
      marketing: false,
      timestamp: new Date().toISOString(),
    }));
    if (window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': 'denied',
        'ad_storage': 'denied'
      });
    }
  };

  const handleSaveSettings = () => {
    localStorage.setItem('cookieConsent', JSON.stringify({
      ...settings,
      timestamp: new Date().toISOString(),
    }));
    if (window.gtag) {
      window.gtag('consent', 'update', {
        'analytics_storage': settings.analytics ? 'granted' : 'denied',
        'ad_storage': settings.marketing ? 'granted' : 'denied'
      });
    }
    setShowSettings(false);
  };

  return (
    <>
      <CookieConsentBanner
        location="bottom"
        buttonText="Accept All"
        declineButtonText="Reject All"
        cookieName="cookieConsent"
        style={{ 
          background: "rgba(0, 0, 0, 0.9)",
          backdropFilter: "blur(10px)",
          padding: "20px",
          maxWidth: "1200px",
          margin: "0 auto",
          borderRadius: "12px",
          marginBottom: "20px",
        }}
        buttonStyle={{ 
          background: "#0085FF",
          color: "white",
          fontSize: "14px",
          borderRadius: "6px",
          padding: "10px 24px",
          fontWeight: "500",
          order: "3",
        }}
        declineButtonStyle={{
          background: "transparent",
          border: "1px solid #0085FF",
          color: "white",
          fontSize: "14px",
          borderRadius: "6px",
          padding: "10px 24px",
          fontWeight: "500",
          marginRight: "10px",
          order: "2",
        }}
        expires={365}
        onAccept={handleAcceptAll}
        onDecline={handleRejectAll}
        enableDeclineButton
      >
        <div className="text-sm text-white/80">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-4">
            <h2 className="text-xl font-semibold text-white">Cookie Preferences</h2>
            <div className="flex gap-3">
              <button
                onClick={() => setShowSettings(true)}
                className="px-6 py-2.5 border border-gray-600 text-white rounded-md hover:bg-gray-800 transition-colors"
                style={{ order: "1" }}
              >
                Customize ▾
              </button>
            </div>
          </div>
          <p className="mb-2">
            We value your privacy and want to be transparent about the data we collect. 
            This website uses cookies and similar technologies to provide necessary website 
            functionality, improve your experience and analyze our traffic. By clicking 
            "Accept All", you consent to our use of cookies. You can "Reject All" non-essential 
            cookies or click "Customize" to manage your preferences.
          </p>
          <p className="mb-2 text-xs text-white/60">
            Data Processing: Cookies are processed within the EU and US under Standard Contractual 
            Clauses. Analytics data is processed by Google Analytics (US) and marketing data by 
            our marketing tools. Data retention varies by cookie type as detailed in settings.
          </p>
          <div className="flex gap-4 mt-4">
            <a 
              href="https://syncramatic.com/privacy" 
              className="text-blue-400 hover:text-blue-300 text-sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <a 
              href="https://syncramatic.com/terms" 
              className="text-blue-400 hover:text-blue-300 text-sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </div>
        </div>
      </CookieConsentBanner>

      {showSettings && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <div className="bg-gray-900 rounded-lg shadow-xl max-w-2xl w-full p-6 border border-white/10">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-white">Cookie Preferences</h2>
              <button
                onClick={() => setShowSettings(false)}
                className="text-gray-400 hover:text-white"
              >
                ✕
              </button>
            </div>
            <div className="space-y-6">
              <div className="flex items-start justify-between p-4 bg-gray-800/50 rounded-lg">
                <div className="flex-1 pr-4">
                  <h3 className="text-white font-medium">Essential Cookies</h3>
                  <p className="text-sm text-white/60 mt-1">
                    Required for the website to function properly. These cookies enable basic 
                    functions like page navigation, secure areas access, and form submissions. 
                    The website cannot function properly without these cookies.
                    <br />
                    <span className="text-xs mt-1 block">
                      Duration: Session only
                      <br />
                      Data Collected: Session ID, CSRF tokens
                      <br />
                      Processing: Local browser only
                    </span>
                  </p>
                </div>
                <div className="flex items-center h-6">
                  <input 
                    type="checkbox" 
                    checked={true} 
                    disabled 
                    className="h-5 w-5 rounded border-gray-300"
                  />
                </div>
              </div>

              <div className="flex items-start justify-between p-4 bg-gray-800/50 rounded-lg">
                <div className="flex-1 pr-4">
                  <h3 className="text-white font-medium">Analytics Cookies</h3>
                  <p className="text-sm text-white/60 mt-1">
                    Help us understand how visitors interact with our website through Google 
                    Analytics. Data collected includes page views, traffic sources, and user 
                    behavior. All data is anonymized and used to improve our services.
                    <br />
                    <span className="text-xs mt-1 block">
                      Duration: 12 months
                      <br />
                      Data Collected: Usage patterns, device info, approximate location
                      <br />
                      Processing: Google Analytics (US) under SCCs
                    </span>
                  </p>
                </div>
                <div className="flex items-center h-6">
                  <input 
                    type="checkbox" 
                    checked={settings.analytics} 
                    onChange={(e) => setSettings({ ...settings, analytics: e.target.checked })}
                    className="h-5 w-5 rounded border-gray-300"
                  />
                </div>
              </div>

              <div className="flex items-start justify-between p-4 bg-gray-800/50 rounded-lg">
                <div className="flex-1 pr-4">
                  <h3 className="text-white font-medium">Marketing Cookies</h3>
                  <p className="text-sm text-white/60 mt-1">
                    Used to deliver personalized advertisements and track campaign effectiveness. 
                    These cookies remember your preferences and browsing patterns to provide 
                    relevant content and marketing communications.
                    <br />
                    <span className="text-xs mt-1 block">
                      Duration: 6 months
                      <br />
                      Data Collected: Browsing preferences, ad interactions
                      <br />
                      Processing: Marketing platforms (EU/US) under SCCs
                    </span>
                  </p>
                </div>
                <div className="flex items-center h-6">
                  <input 
                    type="checkbox" 
                    checked={settings.marketing} 
                    onChange={(e) => setSettings({ ...settings, marketing: e.target.checked })}
                    className="h-5 w-5 rounded border-gray-300"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 flex gap-4">
              <button
                onClick={handleSaveSettings}
                className="flex-1 bg-blue-600 text-white rounded-lg px-4 py-3 hover:bg-blue-700 transition-colors font-medium"
              >
                Save Preferences
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
