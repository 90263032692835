import React from 'react';
import { useEffect, useRef, useState, useCallback } from 'react';

// Shared IntersectionObserver instance for better performance
const createObserver = (options: IntersectionObserverInit): IntersectionObserver | null => {
  if (typeof window === 'undefined') return null;
  
  return new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry && entry.target) {
        const callback = observerCallbacks.get(entry.target);
        if (callback) callback(entry);
      }
    });
  }, options);
};

const observers = new Map<string, IntersectionObserver>();
const observerCallbacks = new Map<Element, (entry: IntersectionObserverEntry) => void>();

interface ScrollAnimationOptions {
  threshold?: number;
  rootMargin?: string;
  triggerOnce?: boolean;
}

interface ScrollAnimationResult {
  ref: (element: HTMLElement | null) => void;
  isVisible: boolean;
}

export const useScrollAnimation = (options: ScrollAnimationOptions = {}): ScrollAnimationResult => {
  const {
    threshold = 0.1,
    rootMargin = '0px',
    triggerOnce = true,
  } = options;

  const [isVisible, setIsVisible] = useState(false);
  const [element, setElement] = useState<HTMLElement | null>(null);

  const observerKey = `${threshold}-${rootMargin}`;
  let observer = observers.get(observerKey);

  if (!observer) {
    observer = createObserver({ threshold, rootMargin }) ?? undefined;
    if (observer) observers.set(observerKey, observer);
  }

  useEffect(() => {
    if (!element || !observer) return;

    const callback = (entry: IntersectionObserverEntry) => {
      const isIntersecting = entry.isIntersecting;
      setIsVisible(isIntersecting);

      if (triggerOnce && isIntersecting && observer) {
        observer.unobserve(element);
        observerCallbacks.delete(element);
      }
    };

    observerCallbacks.set(element, callback);
    observer.observe(element);

    return () => {
      if (observer && element) {
        observer.unobserve(element);
        observerCallbacks.delete(element);
      }
    };
  }, [element, observer, triggerOnce]);

  const ref = useCallback((node: HTMLElement | null) => {
    setElement(node);
  }, []);

  return { ref, isVisible };
};

// Throttle function using requestAnimationFrame
const throttleRAF = <T extends (...args: any[]) => void>(callback: T): T => {
  let requestId: number | null = null;

  const throttled = (...args: Parameters<T>) => {
    if (requestId === null) {
      requestId = requestAnimationFrame(() => {
        callback(...args);
        requestId = null;
      });
    }
  };

  return throttled as T;
};

export const useParallax = (speed: number = 0.5) => {
  const elementRef = useRef<HTMLElement | null>(null);
  const lastScrollY = useRef(0);

  const handleScroll = useCallback(() => {
    requestAnimationFrame(() => {
      if (!elementRef.current) return;

      const scrolled = window.pageYOffset;
      // Skip if scroll change is minimal
      if (Math.abs(scrolled - lastScrollY.current) < 5) return;

      const element = elementRef.current;
      const rect = element.getBoundingClientRect();
      const elementTop = rect.top + scrolled;
      
      // Only apply parallax when element is in viewport
      if (scrolled + window.innerHeight > elementTop && scrolled < elementTop + rect.height) {
        const yPos = -(scrolled - elementTop) * speed;
        element.style.transform = `translate3d(0, ${yPos}px, 0)`;
      }

      lastScrollY.current = scrolled;
    });
  }, [speed]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return elementRef;
};

export const useScrollProgress = () => {
  const [progress, setProgress] = useState(0);
  const lastProgress = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight - windowHeight;
        const scrolled = window.scrollY;
        const newProgress = Math.min(scrolled / documentHeight, 1);

        // Only update state if progress change is significant
        if (Math.abs(newProgress - lastProgress.current) > 0.01) {
          setProgress(newProgress);
          lastProgress.current = newProgress;
        }
      });
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return progress;
};

export const useStickySection = (options: { threshold?: number } = {}) => {
  const { threshold = 0 } = options;
  const sectionRef = useRef<HTMLElement | null>(null);
  const [isSticky, setIsSticky] = useState(false);
  const lastIsSticky = useRef(isSticky);

  useEffect(() => {
    const observer = new IntersectionObserver(
      throttleRAF(([entry]) => {
        const shouldBeSticky = entry.intersectionRatio < 1;
        if (shouldBeSticky !== lastIsSticky.current) {
          setIsSticky(shouldBeSticky);
          lastIsSticky.current = shouldBeSticky;
        }
      }),
      { threshold: [threshold, 1] }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [threshold]);

  return { ref: sectionRef, isSticky };
};
