import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'

// Create a custom hook for isomorphic layout effect
const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const useIsomorphicLayoutEffect = canUseDOM ? React.useLayoutEffect : React.useEffect;

// Override React's useLayoutEffect to prevent SSR warnings
React.useLayoutEffect = useIsomorphicLayoutEffect;

// Hydrate if we have SSR content, otherwise render normally
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
