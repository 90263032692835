import { useEffect, useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Vapi from '@vapi-ai/web/dist/vapi';

interface EmailFormProps {
  isVisible: boolean;
  onSubmit: (email: string) => void;
  onClose: () => void;
  isSubmitting: boolean;
  isSubmitted: boolean;
  className?: string;
}

const EmailForm: React.FC<EmailFormProps> = ({ isVisible, onSubmit, onClose, isSubmitting, isSubmitted, className }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(email);
    setEmail('');
  };

  return (
    <AnimatePresence mode="wait">
      {isVisible && (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 20, opacity: 0 }}
          transition={{ 
            type: "spring",
            stiffness: 100,
            damping: 20,
            mass: 1
          }}
          className={`fixed bottom-0 left-0 right-0 p-4 bg-black/80 backdrop-blur-sm border-t border-white/10 z-50 ${className || ''}`}
        >
          {!isSubmitted ? (
            <motion.form 
              onSubmit={handleSubmit} 
              className="max-w-md mx-auto space-y-4"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3, ease: "easeOut" }}
            >
              <motion.div 
                className="flex flex-col space-y-2"
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1, duration: 0.3 }}
              >
                <label htmlFor="email" className="text-white font-medium">
                  Enter your email address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-accent-blue transition-colors duration-300"
                  placeholder="your@email.com"
                  required
                />
              </motion.div>
              <motion.div 
                className="flex space-x-3"
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.3 }}
              >
                <button
                  type="submit"
                  className="flex-1 px-6 py-2 bg-gradient-to-r from-accent-blue via-accent-purple to-accent-pink rounded-lg text-white font-medium hover:opacity-90 transition-all duration-300"
                >
                  {isSubmitting ? (
                    <>
                      <span className="loading-spinner"></span>
                      Submitting...
                    </>
                  ) : (
                    'Submit'
                  )}
                </button>
                <button
                  type="button"
                  onClick={onClose}
                  className="px-6 py-2 bg-white/10 rounded-lg text-white font-medium hover:bg-white/20 transition-all duration-300"
                >
                  Close
                </button>
              </motion.div>
            </motion.form>
          ) : (
            <motion.div 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ 
                duration: 0.5,
                ease: [0.34, 1.56, 0.64, 1]  // Custom spring-like easing
              }}
              className="max-w-md mx-auto text-center space-y-4 p-6"
            >
              <motion.div 
                animate={{ 
                  scale: [1, 1.1, 1],
                  textShadow: [
                    "0 0 10px rgba(34, 197, 94, 0.5)",
                    "0 0 20px rgba(34, 197, 94, 0.7)",
                    "0 0 10px rgba(34, 197, 94, 0.5)"
                  ]
                }}
                transition={{ 
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
                className="text-green-500 font-bold text-2xl"
              >
                Done
              </motion.div>
              <motion.div 
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.3 }}
                className="text-gray-300 text-lg font-medium"
              >
                Tell Mati you've submitted the email.
              </motion.div>
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const VapiWidget = () => {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [vapi, setVapi] = useState<any>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [emailFormTriggered, setEmailFormTriggered] = useState(() => {
    return localStorage.getItem('emailFormTriggered') === 'true'
  });
  const [sessionId, setSessionId] = useState('');
  const [currentCallId, setCurrentCallId] = useState<string>('');
  const [testEmail, setTestEmail] = useState('test@example.com');
  const [callTimestamp, setCallTimestamp] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [buttonText, setButtonText] = useState('Try AI Voice Agent');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [userSpeaking, setUserSpeaking] = useState(false);
  const [aiSpeaking, setAiSpeaking] = useState(false);

  useEffect(() => {
    localStorage.removeItem('emailFormTriggered');
  }, []);

  useEffect(() => {
    // Monitor initial Vapi call setup
    const originalFetch = window.fetch;
    window.fetch = async function(...args) {
      const [url, config] = args;
      
      // Specifically watch for the call/web endpoint
      if (url.toString().includes('api.vapi.ai/call/web')) {
        try {
          const response = await originalFetch(...args);
          const clonedResponse = response.clone();
          const data = await clonedResponse.json();
          
          // Store the timestamp from call setup
          if (data?.createdAt) {
            setCallTimestamp(data.createdAt);
          }

          return response;
        } catch (error) {
          console.error(' Call Setup Error:', error);
          throw error;
        }
      }

      return originalFetch(...args);
    };

    return () => {
      window.fetch = originalFetch;
    };
  }, []);

  const initializeVapi = useCallback(async () => {
    try {
      setError(null);
      setIsLoading(true);
      setButtonText('Loading...');
      
      console.log('Initializing Vapi...');

      // Initialize with just the API key
      const vapiInstance = new Vapi(
        "c853a11d-afdb-4382-b345-56645e9706d9"
      );

      // Set up event listeners
      vapiInstance.on('error', (error) => {
        console.error('Vapi error:', error);
        setError(error.message || 'An error occurred');
        setIsCallActive(false);
        setButtonText('Try AI Voice Agent');
      });

      type VapiEvent = {
        status: string;
        transcript?: string;
        error?: string;
      };

      vapiInstance.on('stateChange' as any, (state: VapiEvent) => {
        setIsSpeaking(state.status === 'speaking');
        console.log('Vapi state changed:', state);
      });

      vapiInstance.on('message', (message) => {
        console.log('Vapi message:', message);
        if (message.type === 'speech-update') {
          setIsSpeaking(message.status === 'started');
        }
        // Only trigger email form if it hasn't been triggered in this browser session
        if (message.type === 'transcript' && 
            message.transcriptType === 'final' && 
            message.transcript?.toLowerCase().includes('email') && 
            !localStorage.getItem('emailFormTriggered')) {
          localStorage.setItem('emailFormTriggered', 'true');
          setEmailFormTriggered(true);
          setShowEmailForm(true);
        }
      });

      setVapi(vapiInstance);
      setIsInitialized(true);
      setIsLoading(false);
      setButtonText('Try AI Voice Agent');
      console.log('Vapi initialized successfully');
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to initialize Vapi';
      console.error('Error initializing Vapi:', errorMessage);
      setError(errorMessage);
      setIsLoading(false);
      setButtonText('Try AI Voice Agent');
    }
  }, []);

  useEffect(() => {
    initializeVapi();
  }, [initializeVapi]);

  const handleStartCall = useCallback(async () => {
    if (!vapi || !isInitialized) {
      console.error('Vapi not initialized');
      return;
    }

    try {
      setButtonText('Loading...');
      console.log('Starting call with Vapi...');
      
      // Request microphone permission
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });

      // Start the call
      await vapi?.start("180d2681-6540-4db0-9a5c-11c2ab1302fb");
      
      setIsCallActive(true);
      setButtonText(''); // Hide text during call
      console.log('Call started successfully');
    } catch (error) {
      console.error('Error starting call:', error);
      setError(error instanceof Error ? error.message : 'Failed to start call');
      setIsCallActive(false);
      setButtonText('Try AI Voice Agent');
    }
  }, [vapi, isInitialized]);

  useEffect(() => {
    if (vapi) {
      vapi.on('message', (message: any) => {
        // Track user speaking
        if (message.type === 'transcript' && message.transcriptType === 'partial') {
          setUserSpeaking(true);
          // Reset after short delay
          setTimeout(() => setUserSpeaking(false), 100);
        }
        
        // Track AI speaking
        if (message.type === 'speech-update') {
          setAiSpeaking(message.status === 'started');
        }
      });
    }
  }, [vapi]);

  const handleCallEnd = useCallback(() => {
    if (vapi) {
      vapi.cleanup();
      setIsCallActive(false);
      setButtonText('Try AI Voice Agent');
      setIsButtonLoading(false);
    }
  }, [vapi]);

  const handleEmailSubmit = async (email: string) => {
    try {
      setIsSubmitting(true);
      console.log(' Submitting email with timestamp:', {
        email,
        timestamp: callTimestamp
      });

      // Send only to Make.com webhook
      const response = await fetch('https://hook.us2.make.com/p5z628ou1vn6l4nkojv7xxmnyuj15jui', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          timestamp: callTimestamp
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send to webhook');
      }

      setIsSubmitted(true);
      console.log(' Email submitted successfully');
      
      // Wait 9 seconds before closing
      setTimeout(() => {
        setShowEmailForm(false);
      }, 9000);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to send email';
      console.error(' Email submission error:', errorMessage);
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleButtonClick = useCallback(async () => {
    if (error) {
      setButtonText('Loading...');
      await initializeVapi();
    } else if (isCallActive) {
      setButtonText('Loading...');
      try {
        await vapi?.stop();
        setIsCallActive(false);
        setIsSpeaking(false);
        setButtonText('Try AI Voice Agent');
      } catch (error) {
        console.error('Error stopping call:', error);
        setButtonText('Try AI Voice Agent');
      }
    } else {
      await handleStartCall();
    }
  }, [error, isCallActive, vapi, handleStartCall, initializeVapi]);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes gradientHighlight {
        0% { 
          box-shadow: 0 0 20px 5px rgba(16, 185, 129, 0.3),
                      0 0 40px 10px rgba(139, 92, 246, 0.2);
          transform: scale(1);
        }
        50% { 
          box-shadow: 0 0 30px 10px rgba(139, 92, 246, 0.4),
                      0 0 60px 15px rgba(16, 185, 129, 0.3);
          transform: scale(1.02);
        }
        100% { 
          box-shadow: 0 0 20px 5px rgba(16, 185, 129, 0.3),
                      0 0 40px 10px rgba(139, 92, 246, 0.2);
          transform: scale(1);
        }
      }

      .highlight-form {
        animation: gradientHighlight 6s cubic-bezier(0.4, 0, 0.2, 1) !important;
      }
    `;
    document.head.appendChild(style);
    return () => {
      if (style && style.parentNode) {
        style.parentNode.removeChild(style);
      }
    };
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .loading-spinner {
        width: 20px;
        height: 20px;
        border: 2px solid #f3f3f3;
        border-top: 2px solid #0085FF;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        display: inline-block;
        margin-right: 8px;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(style);
    return () => {
      if (style && style.parentNode) {
        style.parentNode.removeChild(style);
      }
    };
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes vuMeterWave {
        0% { transform: scaleY(0.2); }
        50% { transform: scaleY(1); }
        100% { transform: scaleY(0.2); }
      }
      
      .vu-meter {
        position: absolute;
        inset: -4px;  
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
      
      .vu-meter.speaking {
        opacity: 0.6;
      }
      
      .vu-bar {
        width: 2px;
        height: 85%;
        background: currentColor;
        transform-origin: center;
        animation: vuMeterWave 0.5s ease-in-out infinite;
      }
      
      .vu-bar:nth-child(2) { animation-delay: 0.1s; }
      .vu-bar:nth-child(3) { animation-delay: 0.2s; }
      .vu-bar:nth-child(4) { animation-delay: 0.3s; }
      .vu-bar:nth-child(5) { animation-delay: 0.4s; }
    `;
    document.head.appendChild(style);
    return () => {
      if (style && style.parentNode) {
        style.parentNode.removeChild(style);
      }
    };
  }, []);

  const handleTranscriptUpdate = (transcript: string) => {
    if (transcript.toLowerCase().includes('email')) {
      setShowEmailForm(true);
    }
  };

  useEffect(() => {
    if (vapi) {
      vapi.on('transcript', handleTranscriptUpdate);
      return () => {
        vapi.off('transcript', handleTranscriptUpdate);
      };
    }
  }, [vapi]);

  return (
    <div className="relative flex items-center">
      <div className="flex flex-col items-center">
        <div className="relative">
          <motion.div
            className="absolute inset-0 rounded-full bg-gradient-to-r from-green-300 via-green-500 to-green-300"
            style={{ padding: '2px' }}
          >
            <div className="w-full h-full bg-black rounded-full" />
          </motion.div>
          <motion.button
            onClick={handleButtonClick}
            className={`relative p-6 rounded-full transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed 
              ${isCallActive ? 'hover:bg-red-500/10' : 'hover:bg-white/5'}`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={!isInitialized || isLoading}
            aria-label={isCallActive ? "Stop Voice Agent" : "Start Voice Agent"}
            aria-pressed={isCallActive}
            role="button"
            title={isCallActive ? "Click to stop the voice agent" : "Click to start the voice agent"}
          >
            {/* VU Meter Animation - Only visible during active call and speech */}
            {isCallActive && (
              <div className={`vu-meter ${userSpeaking || aiSpeaking ? 'speaking' : ''}`} 
                   style={{ color: userSpeaking ? '#10B981' : aiSpeaking ? '#8B5CF6' : 'transparent' }}>
                <div className="vu-bar" />
                <div className="vu-bar" />
                <div className="vu-bar" />
                <div className="vu-bar" />
                <div className="vu-bar" />
              </div>
            )}

            {/* Mic Icon */}
            <motion.div
              animate={isCallActive ? { scale: [1, 1.2, 1] } : {}}
              transition={{ repeat: Infinity, duration: 1 }}
              className="relative z-10"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`w-10 h-10 ${isCallActive ? 'text-red-500' : 'text-green-500'}`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
                />
              </svg>
            </motion.div>
          </motion.button>
        </div>
      </div>

      <AnimatePresence>
        {!isCallActive && (
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 10 }}
            transition={{
              opacity: { duration: 0.3 },
              x: { type: "spring", stiffness: 100, damping: 15 }
            }}
            className="ml-6 flex items-center gap-3"
          >
            <motion.div
              animate={{ x: [-5, 5, -5] }}
              transition={{ 
                duration: 2,
                repeat: Infinity, 
                ease: "easeInOut",
                repeatType: "reverse"
              }}
              className="flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-blue-400 rotate-180"
              >
                <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z" />
              </svg>
            </motion.div>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.3 }}
              className="text-white/70"
              style={{ 
                willChange: 'transform',
                contain: 'layout style paint',
                contentVisibility: 'auto'
              }}
            >
              <span 
                className="text-base font-medium whitespace-nowrap bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400"
                style={{ 
                  display: 'inline-block',
                  willChange: 'transform',
                  transform: 'translateZ(0)'
                }}
              >
                {isButtonLoading ? 'Loading...' : buttonText}
              </span>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      
      {showEmailForm && (
        <EmailForm
          isVisible={showEmailForm}
          onSubmit={handleEmailSubmit}
          onClose={() => setShowEmailForm(false)}
          isSubmitting={isSubmitting}
          isSubmitted={isSubmitted}
          className={!isSubmitted ? 'highlight-form' : ''}
        />
      )}
    </div>
  );
};
